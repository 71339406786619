.videoBg {
  width: 100%;
  height: calc(100vh - 60px);
  object-fit: cover;
  float: right;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.videoOverlay {
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.45);
  right: 0;
}

.loginContent {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: rgba(100, 100, 111, 0.8) 0px 7px 29px 0px;
  padding: 30px 50px;
}

.loginContent img {
  width: 150px;
}

.welcomeMsg p {
  margin: 0;
}

.welcomeMsg p:first-child {
  text-align: left;
  padding: 0px 55px;
  color: rgb(200, 200, 200);
}
.welcomeMsg p:last-child {
  text-align: right;
  padding: 0px 55px;
  color: rgb(200, 200, 200);
}

.textAnimation {
  position: relative;
  margin: 25px 0 10px 0;
  font-size: 50px;
  font-weight: bold;
  background: linear-gradient(to right, #00868e 0, white 10%, #00868e 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glow 5s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  white-space: nowrap;
  padding: 0px 48px;
  z-index: 0 !important;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginForm label {
  text-transform: uppercase;
  color: #00868e;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.loginInput {
  background-color: rgb(235, 235, 235);
  border: none;
  border-radius: 7px;
  height: 50px;
  width: 300px;
  padding: 0px 20px;
  color: rgb(60, 60, 60);
  margin-bottom: 30px;
}

.loginInput:focus {
  outline: none;
}

.loginBtn {
  background-color: #00868e;
  cursor: pointer;
  border: none;
  color: white;
  height: 50px;
  border-radius: 7px;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
}

.loginBtn:hover {
  background-color: #00adb7;
}

.loginMessage {
  font-size: 18px;
  margin: 10px;
  font-weight: bold;
}

.loginMessage.error {
  color: red;
}

.loginMessage.warn {
  color: yellow;
}


@keyframes glow {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 250px;
  }
  100% {
    background-position: 300px;
  }
}
