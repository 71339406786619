.header {
  background-color: #00868e;
  padding: 30px 0;
}

.backAnchor {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.backAnchor label {
  cursor: pointer;
}

.backAnchor svg {
  transform: scale(0.8);
}

.header h1 {
  margin-top: 0px;
  color: white;
  margin-bottom: 0px;
}

body {
  margin: 0;
}

.rightGrid {
  position: fixed;
  width: 100%;
  right: 0;
}

/* Select Style */
.movieInput {
  background-color: rgb(240, 240, 240);
  width: 300px;
  border-radius: 7px;
  border: none;
  color: rgb(80, 80, 80) !important;
}

.MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 0px !important;
}

.selectItem {
  color: rgb(80, 80, 80) !important;
}

.selectItem + .Mui-selected {
  background-color: rgba(0, 134, 142, 0.3) !important;
  color: white !important;
}

/* Input Style */
.formInput {
  background-color: rgb(240, 240, 240);
  border: none;
  color: rgb(80, 80, 80);
  padding: 16.5px 14px;
  width: 273px !important;
  height: 23px;
  border-radius: 7px;
  font-size: 1rem;
}

/* Input Style */
.formDescription {
  background-color: rgb(240, 240, 240);
  border: none;
  color: rgb(80, 80, 80);
  padding: 16.5px 14px;
  height: "auto";
  min-height: 48px;
  width: auto;
  border-radius: 7px;
  resize: none;
  overflow: auto;
  /*font-family: unset;*/
  font-family: "Arial";
  font-size: 1rem;
}
.formDescription:focus {
  outline: none;
}

#coordinatesInput {
  background-color: transparent;
  border: none;
  color: rgb(80, 80, 80);
  padding: 16.5px 0px;
  width: 273px !important;
  height: 23px;
  border-radius: 7px;
  font-size: 1rem;
}

.formInput:focus {
  outline: none;
}

.suggestionList {
  position: absolute;
  list-style: none;
  padding: 0px 0px;
  border-radius: 7px;
  width: 300px;
  margin: 0;
  margin-top: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  z-index: 1;
}

.suggestionItem {
  padding: 10px 10px;
  cursor: pointer;
}

.suggestionItem:first-child {
  border-radius: 7px 7px 0 0;
}

.suggestionItem:last-child {
  border-radius: 0px 0px 7px 7px;
}

.suggestionItem:hover {
  background-color: rgb(245, 245, 245);
}

.suggestionItem strong {
  color: #00868e;
}

.formInput::placeholder {
  color: rgb(100, 100, 100);
}

.sliderItem {
  position: relative !important;
  height: inherit !important;
  width: 100%;
  object-fit: cover;
}

.removeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00868e;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
}

.removeBtn svg {
  transform: rotate(45deg) scale(0.8);
  color: white;
}

.formBtn {
  background-color: #00868e;
  border-radius: 4px;
  border: none;
  height: 56px;
  color: white;
  margin-bottom: 50px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  text-transform: uppercase;
  padding: 0 20px;
}

.formBtn:hover {
  background-color: rgba(0, 134, 142, 0.8);
}

/*DOCS/AUDIO*/
.documentsSection {
  margin-top: 30px;
  min-height: 100px;
}

.documentsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.addMarginLinks {
  margin-top: 20px;
}
.addPaddingLinks {
  padding-top: 5px;
}

.documentsController {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.documentsController > input {
  display: none;
}

.documentsController label {
  height: 36px;
  width: 36px;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  background-color: #00868e;
}

.documentsController label > svg {
  transform: scale(0.9);
  padding-top: 5px;
}

.docsGrid {
  min-height: 94px;
  margin-right: 100px;
}

.documentItem {
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.documentIcon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.documentIcon svg {
  scale: 2.5;
  fill: rgba(200, 200, 200, 0.5);
}

.documentItem p {
  margin-bottom: 0 !important;
}

.fileExtension {
  background-color: rgb(228, 38, 38);
  color: white;
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 0px;
  font-size: 15px;
  border-radius: 2px;
}

.fileExtension label {
  padding: 10px 5px;
}

.fileName {
  text-align: center;
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
  word-break: break-word;
  padding: 0px 15px;
}

.docRemove {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00868e;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
}

.docRemove svg {
  transform: rotate(45deg) scale(0.8);
  color: white;
}

.removeBtnLink {
  position: absolute;
  /* top: 10px;
  right: 10px; */
  background-color: #00868e;
  display: flex;
  width: 30px;
  height: 55px;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 40px;
}

.removeBtnLink svg {
  transform: rotate(45deg) scale(0.8);
  color: white;
}

.buttonAudioLinkInput {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.buttonAudioLinkInput > input {
  display: none;
}

.buttonAudioLinkInput label {
  height: 36px;
  width: 36px;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  background-color: #00868e;
  /* margin-left: 768px; */
  margin-left: 760px;
}

.buttonAudioLinkInput label > svg {
  transform: scale(0.9);
  padding-top: 5px;
}

.Mui-checked {
  color: #00868e !important;
}
.MuiSwitch-track {
  background-color: #00868e !important;
}
.MuiSwitch-thumb {
  box-shadow: 1px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 7px 1px rgba(0, 0, 0, 0.14), 1px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.formControlLabel {
  font-family: Arial;
  color:  rgb(80, 80, 80);
}

@media only screen and (max-width: 1440px) {
  .buttonAudioLinkInput label {
    /* margin-left: 660px; */
    margin-left: 668px;
  }
}

@media only screen and (max-width: 1280px) {
  .buttonAudioLinkInput label {
    margin-left: 580px;
  }
}
