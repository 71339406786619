
.secondaryFooterContainer {
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  background-color: #f0f0f0;
}

.secondaryFooter {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: black;
  font-size: 13px;
  padding-left: 1em;
  padding-right: 1em;
}

.footerEurope {
  margin-right: 15px;
  height: 40px;
}

.footerText {
  color: #333333;
  letter-spacing: .1px;
  text-align: center;
  max-width: 838px;
  font-size: 13px;
}

.footerText p {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 480px) {
  .footerEurope {
    margin-right: 5px;
    height: 30px;
  }
  
  .footerText {
    font-size: 10px;
  }
}