.modal {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: fit-content;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

.modal:focus {
  outline: none;
}

.alertIcon {
  transform: scale(3);
  margin-bottom: 30px;
  margin-top: 15px;
  fill: rgb(90, 90, 90) !important;
}

.splittedMsg p {
  margin: 0;
}

.splittedMsg p:first-child {
  font-size: 18px;
  color: rgb(90, 90, 90);
  font-weight: bold;
}

.splittedMsg p:last-child {
  font-size: 25px;
  color: #00868e;
  font-weight: bold;
}

.modalBtns {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.modalBtns button {
  height: 40px;
  width: 100px;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
}

.modalBtns button:hover {
  opacity: 0.85;
}

.confirmBtn {
  background-color: #00868e;
}

.cancelBtn {
  background-color: rgb(237, 28, 36);
}
