.formField {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}
.inputLabel {
  color: #00868e;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
}

/* MUI select no outline */
/* .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 0px !important;
}

.selectItem {
  color: rgb(80, 80, 80) !important;
}

.Mui-selected {
  background-color: rgba(0, 134, 142, 0.3) !important;
  color: white !important;
}

.formInput {
  background-color: rgb(240, 240, 240);
  border: none;
  width: 273px;
  border-radius: 7px;
  padding: 16px 14px !important;
  height: 23px;
  color: rgb(80, 80, 80);
  font-size: 1rem;
}

.formInput:focus {
  outline: none;
}

.suggestionList {
  list-style: none;
  padding: 0px 0px;
  background-color: rgb(240, 240, 240);
  border-radius: 7px;
  width: 273px;
}

.suggestionItem {
  padding: 10px 10px;
  cursor: pointer;
}

.suggestionItem:hover {
  background-color: rgb(230, 230, 230);
}

.suggestionItem strong {
  color: #00868e;
}

.formInput::placeholder {
  color: rgb(100, 100, 100);
} */
