.mlTable {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-height: 455px;
}

.tableTitles th {
  color: #00868e;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  padding: 16px 16px;
}

.tableTitles th:first-child {
  text-align: left;
}

.tableBody tr td {
  color: rgb(70, 70, 70);
  font-size: 15px;
  padding: 16px;
}

.tableBody tr td:first-child {
  /*width: 450px;*/
}

.tableIcons {
  /* display: flex !important; */
  justify-content: center;
  gap: 5px;
}

.tableIcons > svg {
  transform: scale(0.9);
  cursor: pointer;
}

.tableIcons > svg:first-child {
  fill: rgb(70, 70, 70) !important;
}

.tableIcons > svg:last-child {
  fill: rgb(237, 28, 36) !important;
}
