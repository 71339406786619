.sliderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mediaControllers > input {
  display: none !important;
}

.mediaControllers label {
  cursor: pointer;
  height: 36px;
  width: 36px;
  background-color: #00868e;
  border-radius: 7px;
  color: white;
}

.mediaControllers label:hover {
  background-color: rgba(0, 134, 142, 0.9);
}

.mediaControllers label > svg {
  transform: scale(0.9);
  padding-top: 5px;
}

.sliderLabel {
  color: #00868e;
  text-transform: uppercase;
  font-weight: bold;
}

.mediaControllers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: center;
}

.mediaControllers button {
  height: 36px;
  width: 36px;
  border-radius: 7px;
  border: none;
  text-align: center;
  cursor: pointer;
  padding: 0px;
}

.mediaControllers button > svg {
  transform: scale(0.6);
}

.sliderArrow {
  background-color: rgb(0, 134, 142);
  color: white;
  transition: 0.3s all ease-in-out;
}

.sliderArrow:hover {
  background-color: rgba(0, 134, 142, 0.9);
}

.mediaSlider {
  position: relative;
  height: 220px;
  width: 100%;
  background-color: rgb(240, 240, 240);
}

.slick-list {
  height: 100% !important;
  z-index: 0;
}

.slick-track {
  display: flex !important;
  height: 100% !important;
  gap: 10px !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  position: relative !important;
  height: 100% !important;
}
