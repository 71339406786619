.translationSection {
  margin: 50px 0 50px 0;
}

.tabList button.Mui-selected {
  color: #00868e;
  font-weight: bold;
}

.tagsField {
  margin-top: 20px;
}

.tagsField p {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

.engTags {
  display: flex;
  gap: 15px;
  margin: 10px 0px;
}

.engTags label {
  background: #00868e;
  color: white;
  border-radius: 4px;
  padding: 5px 10px;
}

.MuiInputBase-root {
  background-color: rgb(240, 240, 240) !important;
  margin-right: 0 !important;
  height: 56px !important;
  border: none !important;
  border-radius: 7px;
}

.MuiInput-input{
  padding: 4px 4px 4px 14px !important;
}

.MuiAutocomplete-tag{
  margin-left: 14px !important;
}

.MuiInputBase-root:before {
  border: none !important;
}
.MuiInputBase-root:after {
  border: none !important;
}

.MuiChip-root{
  background-color: #00868e !important;
  border-radius: 4px !important;
}

.MuiChip-label{
  color: white !important ;
}

.MuiChip-deleteIcon{
  color: white !important;
}

/* .react-tag-input__tag__remove {
  background-color: #00868e !important;
}

.react-tag-input__tag__remove:after,
.react-tag-input__tag__remove:before {
  background-color: white !important;
} */

.documentsSection {
  margin-top: 30px;
  min-height: 100px;
}

.documentsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.documentsController {
  display: flex;
  text-align: center;
}

.documentsController > input {
  display: none;
}

.documentsController label {
  height: 36px;
  width: 36px;
  border-radius: 7px;
  cursor: pointer;
  color: white;
  background-color: #00868e;
}

.documentsController label > svg {
  transform: scale(0.9);
  padding-top: 5px;
}

.docsGrid {
  min-height: 94px;
}

.documentItem {
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.documentIcon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.documentIcon svg {
  scale: 2.5;
  fill: rgba(200, 200, 200, 0.5);
}

.documentItem p {
  margin-bottom: 0 !important;
}

.fileExtension {
  background-color: rgb(228, 38, 38);
  color: white;
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 0px;
  font-size: 15px;
  border-radius: 2px;
}

.fileExtension label {
  padding: 10px 5px;
}

.fileName {
  text-align: center;
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
  word-break: break-word;
  padding: 0px 15px;
}

.docRemove {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00868e;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
}

.docRemove svg {
  transform: rotate(45deg) scale(0.8);
  color: white;
}

.tagsMsg {
  font-size: 12px;
  color: rgb(150, 150, 150);
  /* padding-left: 15px; */
}


.formInput {
  background-color: rgb(240, 240, 240);
  border: none;
  color: rgb(80, 80, 80);
  padding: 16.5px 14px;
  width: 273px !important;
  height: 23px;
  border-radius: 7px;
  font-size: 1rem;
}

.formInputDisabled {
  background-color: rgb(240, 240, 240, 0.38);
  border: none;
  color: rgb(80, 80, 80, 0.38);
  padding: 16.5px 14px;
  width: 273px !important;
  height: 23px;
  border-radius: 7px;
  font-size: 1rem;
}

.formDescriptionDisabled {
  background-color: rgb(240, 240, 240, 0.38);
  border: none;
  color: rgb(80, 80, 80, 0.38);
  padding: 16.5px 14px;
  height: 'auto';
  min-height: 48px; 
  width: auto;
  border-radius: 7px;
  resize: none;
  overflow: auto;
  font-family: 'Arial';
  font-size: 1rem;
}

.formDescription {
  background-color: rgb(240, 240, 240);
  border: none;
  color: rgb(80, 80, 80);
  padding: 16.5px 14px;
  height: 'auto';
  min-height: 48px; 
  width: auto;
  border-radius: 7px;
  resize: none;
  overflow: auto;
  font-family: 'Arial';
  font-size: 1rem;
}
.formDescription:focus{
  outline: none;
}