.headerContent {
  position: relative;
}

.pageLoading {
  opacity: 0.5;
  pointer-events: none;
  position: relative;
}

.tableLoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
}

.waveBg {
  background-image: url("../../assets/img/wave.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 427px;
  top: -100px;
  left: 0;
}

.blueBg {
  height: 0;
  background-color: #00868e;
}

.headerContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0 50px 0;
  position: relative;
}

.headerContent h1 {
  color: white;
  margin: 0;
}

.headerContent button {
  background-color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #00868e;
  cursor: pointer;
}

.headerContent button > svg {
  transform: scale(0.8);
}

.leftSide {
  display: flex;
  flex-direction: column;
}

.userInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  gap: 15px;
}

.userInfo > div {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.tableSection {
  margin-top: 100px;
  margin-bottom: 100px;
}

.tableFilters {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.searchTextField {
  width: 350px;
  border-bottom: 1px solid rgb(200, 200, 200) !important;
}

.tableFilters svg {
  color: #00868e;
}

.tableFilters button {
  background-color: #00868e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.tableFilters button:hover {
  background-color: rgba(0, 134, 142, 0.85);
}

/* Pagination */

.paginationBtns {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 10px;
  float: right;
  height: 30px;
  align-items: center;
  position: relative;
}

.paginationBtns a {
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid #00868e;
  border-radius: 4px;
  text-align: center;
  line-height: 31px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.paginationBtns a:hover {
  background-color: #00868e;
  color: white;
}

.activePagination {
  background-color: #00868e;
  color: white;
  border-radius: 4px;
}

.controllersBtn {
  color: #00868e;
}

.paginationDisabled {
  opacity: 0.5;
}

@media only screen and (max-width: 1800px) {
  .waveBg {
    top: -50px;
  }
}

@media only screen and (max-width: 1500px) {
  .waveBg {
    top: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .blueBg {
    height: 20px;
  }
  
  .waveBg {
    top: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  .blueBg {
    height: 50px;
  }
  
  .waveBg {
    top: 50px;
  }

  .headerContent {
    padding-top: 10px;
  }
}
